import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Paths from "constants/path.constants";
import Breadcrumbs from "components/common/breadcrumbs";
import GeneralComponent from "../edit/sections/general";
import ProbabilitiesComponent from "../edit/sections/probabilities";
import CompetitionsAndRacesComponent from "../edit/sections/probabilities/racing/competitionsAndRaces";
import RTPComponent from "../edit/sections/RTP";
import TrackBannersComponent from "../edit/sections/trackBanners";
import BannersAdjustment from "./sections/bannersAdjustment";
import StreamConfigurationComponent from "../edit/sections/streamConfig";
import { getGameGeneralInfo } from "store/actions/dashboard/virtuals/games/general.action";
import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue, isRacingGame } from "utils/common";
import { GAME_CATEGORY, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Game Edit Page Component */
const GameEditComponent = ({ gameName, gameType, getGameGeneralInfo, gameCategory }) => {
	const routeParams = useParams();
	const { hash } = useLocation();
	const { t } = useTranslation();

	const [activeKey, setActiveKey] = useState(getHashValue("tab") || "1");
	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf GameEditComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf GameEditComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	/** Load game general info */
	useEffect(() => {
		if (routeParams.id) {
			getGameGeneralInfo(routeParams.id, gameCategory);
		}
	}, [routeParams.id, gameCategory]);

	/** Fires on active tab change
	 * @function
	 * @param {string} key - tab key
	 * @memberOf GameEditComponent
	 */
	const handleTabChange = (key) => {
		updateLocationHash("tab=" + key);
	};

	/** Update active tab on location hash change */
	useEffect(() => {
		setActiveKey(getHashValue("tab") || "1");
	}, [hash]);

	const gameText =
		gameCategory === GAME_CATEGORY.SCHEDULED ? "virtual_scheduled_games" : gameCategory === GAME_CATEGORY.INSTANT ? "virtual_instant_games" : "";
	const pervRoute =
		gameCategory === GAME_CATEGORY.SCHEDULED
			? Paths.DASHBOARD_SCHEDULED_GAMES
			: gameCategory === GAME_CATEGORY.INSTANT
				? Paths.DASHBOARD_INSTANT_GAMES
				: "";

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t(`pages.dashboard.games.${gameText}`), path: pervRoute }, { title: `${t("common.edit")} ${gameName}` }]} />
				{gameType && (
					<Tabs
						animated={false}
						onChange={(key) => handleTabChange(key)}
						activeKey={activeKey}
						items={[
							hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW })
								? {
										key: "1",
										label: <span className={tabClassName("1")}>{t("pages.dashboard.games.tabs.general")}</span>,
										children: (
											<GeneralComponent
												onTabChange={(status) => changeTabSavedStatus(status, "1")}
												activeTabKey={activeKey}
												gameCategory={gameCategory}
											/>
										)
									}
								: null,
							gameCategory === GAME_CATEGORY.SCHEDULED &&
							![SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(
								gameType
							) &&
							hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW })
								? {
										key: "5",
										label: <span className={tabClassName("5")}>{t("pages.dashboard.games.tabs.stream_configs")}</span>,
										children: (
											<StreamConfigurationComponent
												onTabChange={(status) => changeTabSavedStatus(status, "5")}
												gameCategory={gameCategory}
											/>
										)
									}
								: null,
							![SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value, SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value].includes(
								gameType
							) && hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.VIEW })
								? {
										key: "2",
										label: <span className={tabClassName("2")}>{t("pages.dashboard.games.tabs.probabilities_and_formats")}</span>,
										children: (
											<ProbabilitiesComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} gameCategory={gameCategory} />
										)
									}
								: null,
							gameCategory === GAME_CATEGORY.SCHEDULED &&
							gameType === SCHEDULED_GAME_TYPE.KENO.value &&
							hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.VIEW })
								? {
										key: "2",
										label: <span className={tabClassName("2")}>{t("pages.dashboard.games.tabs.formats")}</span>,
										children: (
											<CompetitionsAndRacesComponent
												onTabChange={(status) => changeTabSavedStatus(status, "2")}
												gameCategory={gameCategory}
											/>
										)
									}
								: null,
							hasPermission({ resource: PERMISSION_RESOURCE.GAME_RTP, action: PERMISSION_ACTION.VIEW })
								? {
										key: "3",
										label: (
											<span className={tabClassName("3")}>
												{t(`pages.dashboard.games.tabs.${gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value ? "lucky_six_" : ""}rtps`)}
											</span>
										),
										children: (
											<RTPComponent
												gameType={gameType}
												onTabChange={(status) => changeTabSavedStatus(status, "3")}
												gameCategory={gameCategory}
											/>
										)
									}
								: null,
							gameCategory === GAME_CATEGORY.SCHEDULED &&
							isRacingGame(gameType) &&
							gameType !== SCHEDULED_GAME_TYPE.STEEPLECHASING.value &&
							hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.VIEW })
								? {
										key: "4",
										label: <span className={tabClassName("4")}>{t("pages.dashboard.games.tabs.banners")}</span>,
										children: <TrackBannersComponent gameCategory={gameCategory} />
									}
								: null,
							gameCategory === GAME_CATEGORY.SCHEDULED &&
							[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value, SCHEDULED_GAME_TYPE.STEEPLECHASING.value].includes(gameType) &&
							hasPermission({ resource: PERMISSION_RESOURCE.GAME_TRACK_BRANDING, action: PERMISSION_ACTION.VIEW })
								? {
										key: "4",
										label: <span className={tabClassName("4")}>{t("pages.dashboard.games.tabs.bannersAdjustment")}</span>,
										children: <BannersAdjustment gameType={gameType} gameCategory={gameCategory} />
									}
								: null
						]}
					/>
				)}
			</div>
		</Fragment>
	);
};

/** GameEditComponent propTypes
 * PropTypes
 */
GameEditComponent.propTypes = {
	/** Redux state property, represents the name of current editing game  */
	gameName: PropTypes.string,
	/** Redux state property, represents the type of current editing game  */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE).map((v) => v.value), ...Object.values(INSTANT_GAME_TYPE).map((v) => v.value)]),
	/** Redux action to get game General info */
	getGameGeneralInfo: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapStateToProps = (state) => {
	return {
		gameName: state.games.editingGame.generalInfo.name,
		gameType: state.games.editingGame.generalInfo.type
	};
};

const mapDispatchToProps = (dispatch) => ({
	getGameGeneralInfo: (id, gameCategory) => {
		dispatch(getGameGeneralInfo(id, gameCategory));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(GameEditComponent);
