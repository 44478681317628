import EventsComponent from "components/dashboard/events/sections/common/index.jsx";
import { PROJECT_TYPE } from "constants/common.constants.js";
import { useSelector } from "react-redux";

const SSBTEventsComponent = ({ activeTab, gameCategory }) => {
	const events = useSelector((state) => state.events.ssbt[gameCategory].events);
	const sorting = useSelector((state) => state.events.ssbt[gameCategory].sorting);
	const total = useSelector((state) => state.events.ssbt[gameCategory].total);
	const filters = useSelector((state) => state.events.ssbt[gameCategory].filters);

	return (
		<EventsComponent
			events={events}
			sorting={sorting}
			filters={filters}
			total={total}
			projectType={PROJECT_TYPE.SSBT}
			activeTab={activeTab}
			gameCategory={gameCategory}
		/>
	);
};

export default SSBTEventsComponent;
