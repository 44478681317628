import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { isSeasonGame } from "utils/common";
import { SCHEDULED_GAME_TYPE, SPIN_TO_WIN_COLOR_MAPPER } from "constants/game.constants";
import { PERIOD } from "constants/market.constants";
import Ball from "components/common/luckySix/ball/index.jsx";
import { LUCKY_SIX_COLOR_CLASSES } from "constants/game.constants.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRTPs } from "store/actions/dashboard/virtuals/games/rtp.action.js";
import ColorResultBadge from "components/common/spin2Win/colorResultBadge/index.jsx";

const Scenes = ({ event, gameCategory }) => {
	const rtPs = useSelector((state) => state.games.editingGame.rtp.rtPs);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const data = event?.gameData?.scenes ?? [];

	useEffect(() => {
		if (event.gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
			dispatch(getRTPs(event?.gameId, "none", gameCategory));
		}
	}, [event, gameCategory]);

	return (
		<div className="dashboard-result-view-scenes">
			{(event.gameType === SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value || isSeasonGame(event.gameType)) && data.length ? (
				<Row gutter={[16, 0]}>
					<>
						<Col xs={24} sm={24} lg={12}>
							<span className="dashboard-result-view-scenes-period">{t("pages.dashboard.events.1_half")}</span>
							{event.gameData.scenes
								.filter((sc) => sc.period === PERIOD.FIRSTHALF)
								.map((scene, index) => (
									<div className="dashboard-result-view-scene" key={`${scene.name}_${index}`}>
										<span className="dashboard-result-view-scene-index">{index + 1}.</span>
										<span className="dashboard-result-view-scene-title" title={scene.name}>
											{scene.name}
										</span>
									</div>
								))}
						</Col>
						<Col xs={24} sm={24} lg={12}>
							<span className="dashboard-result-view-scenes-period">{t("pages.dashboard.events.2_half")}</span>
							{event.gameData.scenes
								.filter((sc) => sc.period === PERIOD.SECONDHALF)
								.map((scene, index) => (
									<div className="dashboard-result-view-scene" key={`${scene.name}_${index}`}>
										<span className="dashboard-result-view-scene-index">{index + 1}.</span>
										<span className="dashboard-result-view-scene-title" title={scene.name}>
											{scene.name}
										</span>
									</div>
								))}
						</Col>
					</>
				</Row>
			) : event.gameType === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value && data.length ? (
				<>
					{
						<Row gutter={[16, 0]}>
							{event.gameData.scenes
								.filter((sc) => sc.period === PERIOD.PENALTY_SHOOTOUT)
								.map((scene, index) => (
									<Col xs={24} sm={24} lg={12} key={`${scene.name}_${index}`}>
										<div className="dashboard-result-view-scene">
											<span className="dashboard-result-view-scene-index">{index + 1}.</span>
											<span className="dashboard-result-view-scene-title" title={scene.name}>
												{scene.name}
											</span>
										</div>
									</Col>
								))}
						</Row>
					}
				</>
			) : event.gameType === SCHEDULED_GAME_TYPE.KENO.value ? (
				<div className="dashboard-result-view-scenes-keno">
					{data.map((sc) => (
						<div className="dashboard-result-view-scenes-keno-ball" key={sc.number}>
							<div className="dashboard-result-view-scenes-keno-ball-inner">
								<span>{sc.number}</span>
							</div>
						</div>
					))}
				</div>
			) : event.gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value ? (
				<div className="dashboard-result-view-scenes-lucky-six">
					<div className="dashboard-result-view-scenes-lucky-six-header">
						{data.slice(0, 5).map((ballData) => (
							<Ball number={ballData.number} color={LUCKY_SIX_COLOR_CLASSES[ballData.ballColour]} key={ballData.number} />
						))}
					</div>
					<div className="dashboard-result-view-scenes-lucky-six-body">
						{data.slice(5).map((ballData, index) => {
							const margin = rtPs.find((rtp) => rtp.orderNumber === index + 1)?.margin || 1;

							return (
								<div className="ball-info" key={ballData.number}>
									<Ball number={ballData.number} color={LUCKY_SIX_COLOR_CLASSES[ballData.ballColour]} />
									<span>{margin}x</span>
								</div>
							);
						})}
					</div>
				</div>
			) : event.gameType === SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value ? (
				<div className="dashboard-result-view-scenes-spin2Win">
					<div className="dashboard-result-view-scenes-spin2Win-header">
						{data.map((colorData) => (
							<ColorResultBadge key={colorData.time} color={SPIN_TO_WIN_COLOR_MAPPER[colorData?.colour]} number={colorData.number} />
						))}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Scenes;
