import { useDispatch, useSelector } from "react-redux";
import TransactionsTableComponent from "components/dashboard/transactions/table.component.jsx";
import { PROJECT_TYPE } from "constants/common.constants.js";
import { TRANSACTION_TYPE } from "constants/transactions.constants.js";
import {
	getTransactionHistory,
	getTransactions,
	resendTransaction,
	setTransactionsFilters,
	setTransactionsSorting
} from "store/actions/dashboard/transactions/transactions.action.js";

const SSBTTransactionsComponent = () => {
	const isLoading = useSelector((state) => state.transactions.isLoading);
	const transactions = useSelector((state) => state.transactions.results.ssbt.transactions);
	const total = useSelector((state) => state.transactions.results.ssbt.total);
	const sorting = useSelector((state) => state.transactions.results.ssbt.sorting);
	const filters = useSelector((state) => state.transactions.results.ssbt.filters);

	const dispatch = useDispatch();

	return (
		<TransactionsTableComponent
			getTransactions={(fromFirstPage) => dispatch(getTransactions(PROJECT_TYPE.SSBT, TRANSACTION_TYPE.RESULT, fromFirstPage))}
			getTransactionHistory={(id) => dispatch(getTransactionHistory(id, PROJECT_TYPE.SSBT, TRANSACTION_TYPE.RESULT))}
			setTransactionsSorting={(sorting) => dispatch(setTransactionsSorting(sorting, PROJECT_TYPE.SSBT, TRANSACTION_TYPE.RESULT))}
			setTransactionsFilters={(filters) => dispatch(setTransactionsFilters(filters, PROJECT_TYPE.SSBT, TRANSACTION_TYPE.RESULT))}
			resendTransaction={(id) => dispatch(resendTransaction(id, PROJECT_TYPE.SSBT, TRANSACTION_TYPE.RESULT))}
			transactions={transactions}
			isLoading={isLoading}
			sorting={sorting}
			filters={filters}
			total={total}
			projectType={PROJECT_TYPE.SSBT}
			transactionType={TRANSACTION_TYPE.RESULT}
		/>
	);
};

export default SSBTTransactionsComponent;
