import {
	AUTOSUGGESTION_SET_APIKEY_NAMES,
	AUTOSUGGESTION_SET_GAME_NAMES,
	AUTOSUGGESTION_SET_PARTNERS,
	AUTOSUGGESTION_SET_BETSHOP_NAMES,
	AUTOSUGGESTION_SET_USER_NAMES,
	AUTOSUGGESTION_SET_CASHIER_NAMES,
	AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES,
	AUTOSUGGESTION_SET_CONTROLLERS,
	AUTOSUGGESTION_SET_BONUS_NAMES,
	AUTOSUGGESTION_SET_JACKPOT_NAMES,
	AUTOSUGGESTION_SET_SYSTEM_MARKETS,
	AUTOSUGGESTION_SET_CURRENCY_NAMES,
	AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES,
	AUTOSUGGESTION_SET_TERMINAL_NAMES
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case AUTOSUGGESTION_SET_APIKEY_NAMES:
			return {
				...state,
				apiKeyNames: payload.data
			};
		case AUTOSUGGESTION_SET_GAME_NAMES:
			return {
				...state,
				gameNames: payload.data
			};
		case AUTOSUGGESTION_SET_PARTNERS:
			return {
				...state,
				partnersNames: payload.data
			};
		case AUTOSUGGESTION_SET_BETSHOP_NAMES:
			return {
				...state,
				betshopNames: payload.data
			};
		case AUTOSUGGESTION_SET_TERMINAL_NAMES:
			return {
				...state,
				terminalNames: payload.data
			};
		case AUTOSUGGESTION_SET_USER_NAMES:
			return {
				...state,
				userNames: payload.data
			};
		case AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES:
			return {
				...state,
				accessManagerNames: payload.data
			};
		case AUTOSUGGESTION_SET_CASHIER_NAMES:
			return {
				...state,
				cashierNames: payload.data
			};
		case AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES:
			return {
				...state,
				permissionGroupNames: payload.data
			};
		case AUTOSUGGESTION_SET_CONTROLLERS:
			return {
				...state,
				controllers: payload.data
			};
		case AUTOSUGGESTION_SET_BONUS_NAMES:
			return {
				...state,
				bonusNames: payload.data
			};
		case AUTOSUGGESTION_SET_JACKPOT_NAMES:
			return {
				...state,
				jackpotNames: payload.data
			};
		case AUTOSUGGESTION_SET_SYSTEM_MARKETS:
			return {
				...state,
				systemMarkets: payload.data
			};
		case AUTOSUGGESTION_SET_CURRENCY_NAMES:
			return {
				...state,
				currencyNames: payload.data
			};
		default:
			return state;
	}
};
