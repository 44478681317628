import { Fragment } from "react";
import PropTypes from "prop-types";

import EventViewComponent from "components/dashboard/events/event-view.component";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PROJECT_TYPE } from "constants/common.constants";

const EventViewRouteComponent = ({ projectType }) => {
	return (
		<Fragment>
			<EventViewComponent projectType={projectType} />
		</Fragment>
	);
};

EventViewRouteComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
};

const EventViewRoute = withPermission(
	EventViewRouteComponent,
	{ resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.VIEW },
	Paths.DASHBOARD
);

export const OnlineEventViewRoute = () => {
	return <EventViewRoute projectType={PROJECT_TYPE.ONLINE} />;
};

export const RetailEventViewRoute = () => {
	return <EventViewRoute projectType={PROJECT_TYPE.RETAIL} />;
};

export const SSBTEventViewRoute = () => {
	return <EventViewRoute projectType={PROJECT_TYPE.SSBT} />;
};
