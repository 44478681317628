import { useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, Select, Input, DatePicker, Radio, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import useAutosuggestion from "hooks/useAutosuggestion";
import { monthAgo } from "utils/dateTime";
import { FILTER_TRANSACTION_STATUS } from "constants/filter.constants";
import { PROJECT_TYPE } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** Transactions Filters Component */
const Filters = ({ setTransactionsFilters, getTransactions, projectNamesAutosuggestionType, filters, projectType, globalPartnerId }) => {
	const { t } = useTranslation();
	const projectNames = useAutosuggestion(projectNamesAutosuggestionType, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: false }]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "betSlipId", title: t("pages.dashboard.transactions.transaction_id") },
		{
			name: "projectId",
			title:
				projectType === PROJECT_TYPE.ONLINE
					? t("pages.dashboard.transactions.project")
					: projectType === PROJECT_TYPE.RETAIL
						? t("pages.dashboard.transactions.betshop")
						: t("pages.dashboard.transactions.terminal"),
			values: projectNames.map((p) => ({ title: p.value, value: p.key }))
		},
		{
			name: "isFailed",
			title: t("pages.dashboard.transactions.status"),
			values: [
				{ title: t("common.all"), value: FILTER_TRANSACTION_STATUS.ALL },
				{ title: t("pages.dashboard.transactions.success"), value: FILTER_TRANSACTION_STATUS.SUCCESS },
				{ title: t("pages.dashboard.transactions.failed"), value: FILTER_TRANSACTION_STATUS.FAILED }
			]
		}
	];

	return (
		<FiltersWrapper
			filtersName={"transactions_" + projectType}
			loadFn={getTransactions}
			setFiltersFn={setTransactionsFilters}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList()}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.transactions.transaction_id")} name="betSlipId">
							<NumericInput
								placeholder={`${t("common.enter")} ${t("pages.dashboard.transactions.transaction_id")}`}
								isInteger={true}
								maxLength={12}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem
							label={
								projectType === PROJECT_TYPE.ONLINE
									? t("pages.dashboard.transactions.project")
									: projectType === PROJECT_TYPE.RETAIL
										? t("pages.dashboard.transactions.betshop")
										: t("pages.dashboard.transactions.terminal")
							}
							name="projectId"
						>
							<SearchableSelect
								items={projectNames}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.value}
								addNone={true}
								noneText={t("common.all")}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.transactions.status")} name="isFailed">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_TRANSACTION_STATUS.ALL}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								<Select.Option value={FILTER_TRANSACTION_STATUS.SUCCESS}>
									<span title={t("pages.dashboard.transactions.success")}>{t("pages.dashboard.transactions.success")}</span>
								</Select.Option>
								<Select.Option value={FILTER_TRANSACTION_STATUS.FAILED}>
									<span title={t("pages.dashboard.transactions.failed")}>{t("pages.dashboard.transactions.failed")}</span>
								</Select.Option>
							</Select>
						</FormItem>
					</Col>

					<Col xs={24} sm={12} lg={6} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.events.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} ${TIME_FORMAT}`}
								showTime={{ format: TIME_FORMAT }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={16}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/**Function to get online transactions */
	getTransactions: PropTypes.func,
	/**Function to set transactions filters */
	setTransactionsFilters: PropTypes.func,
	/** Transactions filters */
	filters: PropTypes.object,
	/** Project name autosuggestion type */
	projectNamesAutosuggestionType: PropTypes.oneOf(Object.values(AUTOSUGGESTION_TYPE)),
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

export default Filters;
