import TransactionsFilters from "components/dashboard/transactions/filters.component.jsx";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants.js";
import { PROJECT_TYPE } from "constants/common.constants.js";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions, setTransactionsFilters } from "store/actions/dashboard/transactions/transactions.action.js";
import { TRANSACTION_TYPE } from "constants/transactions.constants.js";

const Filters = () => {
	const filters = useSelector((state) => state.transactions.rollbacks.ssbt.filters);
	const globalPartnerId = useSelector((state) => state.partner.globalPartnerId);

	const dispatch = useDispatch();

	return (
		<TransactionsFilters
			getTransactions={() => dispatch(getTransactions(PROJECT_TYPE.SSBT, TRANSACTION_TYPE.ROLLBACK))}
			setTransactionsFilters={(filters) => dispatch(setTransactionsFilters(filters, PROJECT_TYPE.SSBT, TRANSACTION_TYPE.ROLLBACK))}
			projectNamesAutosuggestionType={AUTOSUGGESTION_TYPE.TERMINALS}
			globalPartnerId={globalPartnerId}
			filters={filters}
			projectType={PROJECT_TYPE.SSBT}
		/>
	);
};

export default Filters;
