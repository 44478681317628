import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Tabs } from "antd";

import { useTranslation } from "react-i18next";

import OnlineTransactionsComponent from "./online";
import RetailTransactionsComponent from "./retail";

import { setRollbacksActiveTab } from "store/actions/dashboard/transactions/transactions.action";

import { PROJECT_TYPE } from "constants/common.constants";
import { PARTNER_TYPE } from "constants/partner.constants";

import { binaryToFlags, updateLocationHash, getHashValue } from "utils/common";

import partnerType from "types/partner/partner.type";
import SSBTTransactionsComponent from "components/dashboard/transactions/sections/rollbacks/ssbt/index.jsx";

/** Rollbacks Tab Component */
const RollBacksComponent = ({ setRollbacksActiveTab, globalPartnerId, globalPartners }) => {
	const { t } = useTranslation();

	const globalPartner = globalPartners.find((p) => p.id === globalPartnerId);

	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf RollBacksComponent
	 */
	const isRetail = () => binaryToFlags(Object.values(PARTNER_TYPE), globalPartner?.type).includes(PARTNER_TYPE.RETAIL);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf RollBacksComponent
	 */
	const isOnline = () => binaryToFlags(Object.values(PARTNER_TYPE), globalPartner?.type).includes(PARTNER_TYPE.ONLINE);

	const isSSBT = () => binaryToFlags(Object.values(PARTNER_TYPE), globalPartner?.type).includes(PARTNER_TYPE.SSBT);

	const [activeTab, setActiveTab] = useState(getHashValue("subtab"));

	useEffect(() => {
		if (activeTab !== "") setRollbacksActiveTab(+activeTab);
	}, [activeTab, setRollbacksActiveTab]);

	useEffect(() => {
		const configArr = [isOnline() && PROJECT_TYPE.ONLINE, isRetail() && PROJECT_TYPE.RETAIL, isSSBT() && PROJECT_TYPE.SSBT];

		if (configArr.length > 0 && !configArr.includes(+activeTab)) {
			setActiveTab(configArr.find((config) => config));
		}
	}, [globalPartnerId, globalPartners]);

	return (
		<Tabs
			defaultActiveKey={activeTab.toString()}
			animated={false}
			className="sub-tabs"
			onTabClick={(key) => {
				setActiveTab(key);
				updateLocationHash("subtab=" + key, true);
			}}
			activeKey={activeTab.toString()}
			items={[
				isOnline()
					? {
							key: PROJECT_TYPE.ONLINE.toString(),
							label: t("common.online"),
							children: <OnlineTransactionsComponent />
						}
					: null,
				isRetail()
					? {
							key: PROJECT_TYPE.RETAIL.toString(),
							label: t("common.retail"),
							children: <RetailTransactionsComponent />
						}
					: null,
				isSSBT()
					? {
							key: PROJECT_TYPE.SSBT.toString(),
							label: t("common.ssbt"),
							children: <SSBTTransactionsComponent />
						}
					: null
			]}
		/>
	);
};

/** RollBacksComponent propTypes
 * PropTypes
 */
RollBacksComponent.propTypes = {
	/** Redux action to set rollbacks tab page active tab */
	setRollbacksActiveTab: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerType)
};

const mapDispatchToProps = (dispatch) => ({
	setRollbacksActiveTab: (tab) => {
		dispatch(setRollbacksActiveTab(tab));
	}
});

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RollBacksComponent);
