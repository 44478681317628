import PropTypes from "prop-types";
import OnlineComponent from "./sections/online";
import RetailComponent from "./sections/retail";
import SSBTComponent from "./sections/ssbt";
import { PROJECT_TYPE } from "constants/common.constants";

/** Events Page Component */
const EventsComponent = ({ projectType }) => {
	return (
		<>
			{projectType === PROJECT_TYPE.ONLINE ? (
				<OnlineComponent />
			) : projectType === PROJECT_TYPE.RETAIL ? (
				<RetailComponent />
			) : projectType === PROJECT_TYPE.SSBT ? (
				<SSBTComponent />
			) : null}
		</>
	);
};

/** EventsComponent propTypes
 * PropTypes
 */
EventsComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
};

export default EventsComponent;
