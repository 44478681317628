import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import SingleEventComponent from "./sections/common/single";
import Breadcrumbs from "components/common/breadcrumbs";
import { PROJECT_TYPE } from "constants/common.constants";
import Paths from "constants/path.constants";

/** Event View Page Component */
const EventViewComponent = ({ projectType }) => {
	const params = useParams();
	const eventId = Number(params.id);
	const gameCategory = Number(params.gameCategory);
	const { hash } = useLocation();
	const { t } = useTranslation();
	const path =
		projectType === PROJECT_TYPE.ONLINE
			? Paths.DASHBOARD_EVENTS_ONLINE
			: projectType === PROJECT_TYPE.RETAIL
				? Paths.DASHBOARD_EVENTS_RETAIL
				: projectType === PROJECT_TYPE.SSBT
					? Paths.DASHBOARD_EVENTS_SSBT
					: null;

	return (
		<Fragment>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.bets.event"), path: path ? path + hash : null }, { title: eventId }]} />
				<div className="dashboard-section-content">
					<SingleEventComponent projectType={projectType} eventId={Number(eventId)} gameCategory={gameCategory} />
				</div>
			</div>
		</Fragment>
	);
};

/** EventViewComponent propTypes
 * PropTypes
 */
EventViewComponent.propTypes = {
	/** The Project Type (online/retail) */
	projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE))
};

export default EventViewComponent;
