import { useState, useEffect, Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Col, Row, Modal, Select, Input, Checkbox, Radio } from "antd";
const { Item: FormItem } = Form;

import { createGame, getAllTeams, getGames } from "store/actions/dashboard/virtuals/games/games.action";

import Paths from "constants/path.constants";

import { GAME_CATEGORY, GAME_CHANNEL, GAME_TEAM_FORMAT, INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { ALL_OPTION_ID, POPUP_SIZE } from "constants/common.constants";
import { GAME_CYCLE_MINUTES } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { DYNAMIC_PATH_ID_REGEX } from "constants/regex.constants";

import { hasPermission } from "utils/permissions";

import partnerType from "types/partner/partner.type";

import { isCupGame, isLeagueGame, getGamesFromConfig } from "utils/common";
import SearchableSelect from "components/common/searchableSelect";
import Flag from "components/common/flag";

const getDefaultGame = (category) => {
	if (category === GAME_CATEGORY.SCHEDULED) {
		return SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value;
	}

	if (category === GAME_CATEGORY.INSTANT) {
		return INSTANT_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value;
	}

	return null;
};

/** Game Creating Popup Component */
const GameAddComponent = ({
	isSaving,
	globalPartners,
	globalPartnerId,
	createGame,
	getGames,
	onClose,
	gameCategory,
	getAllTeams,
	allTeams,
	isAllTeamsLoading
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const [selectedPartnerId, setSelectedPartnerId] = useState(globalPartnerId);
	const [currentGameType, setCurrentGameType] = useState(getDefaultGame);
	const [teamFormat, setTeamFormat] = useState(GAME_TEAM_FORMAT.NATIONAL_TEAMS);

	const globalPartner = globalPartners.find((p) => p.id === globalPartnerId);
	const selectedPartner = globalPartners.find((p) => p.id === selectedPartnerId);

	/** Set isTesting to true, when selected partner is test */
	useEffect(() => {
		if (selectedPartner?.isTesting) {
			setTimeout(() => setFieldsValue({ isTesting: true }), 0);
		}
	}, [selectedPartnerId]);

	/** Set default value for cycleMinutes on gameType change */
	useEffect(() => {
		if (gameCategory === GAME_CATEGORY.SCHEDULED) {
			if (
				[
					SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value,
					SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value,
					SCHEDULED_GAME_TYPE.KENO.value,
					SCHEDULED_GAME_TYPE.LUCKY_SIX.value
				].includes(currentGameType) ||
				isLeagueGame(currentGameType)
			) {
				setFieldsValue({ cycleMinutes: GAME_CYCLE_MINUTES.CYCLE3MIN });
			} else {
				setFieldsValue({ cycleMinutes: GAME_CYCLE_MINUTES.CYCLE2MIN });
			}
		}
	}, [gameCategory, currentGameType]);

	useEffect(() => {
		if (gameCategory !== GAME_CATEGORY.SCHEDULED || currentGameType !== SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value) {
			return;
		}
		setFieldsValue({ teamFormat: GAME_TEAM_FORMAT.NATIONAL_TEAMS });
		setTeamFormat(GAME_TEAM_FORMAT.NATIONAL_TEAMS);
	}, [gameCategory, currentGameType]);

	useEffect(() => {
		if (gameCategory !== GAME_CATEGORY.SCHEDULED || currentGameType !== SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value) {
			return;
		}
		setFieldsValue({ teams: [] });
		getAllTeams({ teamFormat: teamFormat });
	}, [gameCategory, currentGameType, teamFormat]);

	const gameEditUrl =
		gameCategory === GAME_CATEGORY.SCHEDULED
			? Paths.DASHBOARD_SCHEDULED_GAMES_EDIT
			: gameCategory === GAME_CATEGORY.INSTANT
				? Paths.DASHBOARD_INSTANT_GAMES_EDIT
				: "";

	/** Fires when form submitted
	 * @function
	 * @memberOf GameAddComponent
	 */
	const handleForm = async () => {
		try {
			const formFields = await validateFields();

			const callback = ({ id }) => {
				if (hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })) {
					navigate(gameEditUrl.replace(DYNAMIC_PATH_ID_REGEX, id + (formFields.partnerId ? "?PI=" + formFields.partnerId : "")));
					return;
				}
				getGames(gameCategory);
				onClose();
			};

			if (formFields.teams?.includes(ALL_OPTION_ID)) {
				formFields.teams = [...allTeams.map((team) => team.countryCode)];
			}

			createGame({ ...formFields, gameCategory }, callback);
		} catch (error) {
			console.log(error);
		}
	};

	const handleGameTypeChange = (e) => {
		setCurrentGameType(e);

		if (e === SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value) {
			setFieldsValue({ gameChannel: GAME_CHANNEL.RETAIL });
		}
	};

	const teamsData = useMemo(
		() => [
			{ text: t("common.all"), value: ALL_OPTION_ID },
			...allTeams.map((team) => ({ text: `${team.countryCode} - ${team.countryName}`, value: team.countryCode }))
		],
		[allTeams, t]
	);

	return (
		<Modal
			open={true}
			title={t("pages.dashboard.games.create_game")}
			cancelText={t("common.cancel")}
			okText={t("common.create")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.MIDDLE}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					type: getDefaultGame(gameCategory),
					name: "",
					partnerId: globalPartnerId,
					isTesting: Boolean(selectedPartner?.isTesting),
					...(gameCategory === GAME_CATEGORY.SCHEDULED
						? {
								cycleMinutes: GAME_CYCLE_MINUTES.CYCLE3MIN,
								gameChannel: currentGameType !== SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value ? GAME_CHANNEL.ONLINE : GAME_CHANNEL.RETAIL
							}
						: null)
				}}
			>
				<Row gutter={[16, 0]}>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.games.game_type")} *`}
							name="type"
							rules={[{ required: true, message: t("validation.field_required") }]}
						>
							<Select getPopupContainer={() => document.body} onChange={handleGameTypeChange} suffixIcon={<i className="icon-down" />}>
								{getGamesFromConfig(gameCategory).map((g) => (
									<Select.Option value={g.value} key={g.value}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col span={24}>
						<FormItem
							label={`${t("pages.dashboard.games.name")} *`}
							name="name"
							rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}
						>
							<Input
								maxLength={50}
								placeholder={`${t("common.enter")} ${t("pages.dashboard.games.name")}`}
								autoFocus={true}
								autoComplete="off"
							/>
						</FormItem>
					</Col>
					{gameCategory === GAME_CATEGORY.SCHEDULED ? (
						<Fragment>
							<Col span={24}>
								<FormItem
									label={
										isLeagueGame(currentGameType)
											? t("pages.dashboard.games.week_cycle")
											: isCupGame(currentGameType)
												? t("pages.dashboard.games.round_cycle")
												: t("pages.dashboard.games.draw_cycle")
									}
									name="cycleMinutes"
								>
									<Select suffixIcon={<i className="icon-down" />}>
										{currentGameType !== SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value ? (
											<Select.Option value={GAME_CYCLE_MINUTES.CYCLE2MIN}>{`2 ${t("common.minutes")}`}</Select.Option>
										) : null}
										<Select.Option value={GAME_CYCLE_MINUTES.CYCLE3MIN}>{`3 ${t("common.minutes")}`}</Select.Option>
										<Select.Option value={GAME_CYCLE_MINUTES.CYCLE4MIN}>{`4 ${t("common.minutes")}`}</Select.Option>
										<Select.Option value={GAME_CYCLE_MINUTES.CYCLE5MIN}>{`5 ${t("common.minutes")}`}</Select.Option>
										<Select.Option value={GAME_CYCLE_MINUTES.CYCLE6MIN}>{`6 ${t("common.minutes")}`}</Select.Option>
									</Select>
								</FormItem>
							</Col>
							<Col span={24}>
								<FormItem
									label={`${t("pages.dashboard.games.channel")} *`}
									name="gameChannel"
									rules={[{ required: true, message: t("validation.field_required") }]}
								>
									<Select suffixIcon={<i className="icon-down" />}>
										<Select.Option disabled={currentGameType === SCHEDULED_GAME_TYPE.SPIN_TO_WIN.value} value={GAME_CHANNEL.ONLINE}>
											{t("pages.dashboard.games.channels.online")}
										</Select.Option>
										<Select.Option value={GAME_CHANNEL.RETAIL}>{t("pages.dashboard.games.channels.retail")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
							{currentGameType === SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value ? (
								<Fragment>
									<Col span={24}>
										<FormItem
											label={`${t("pages.dashboard.games.teamFormat")} *`}
											name="teamFormat"
											rules={[
												{
													validator: (_, value) => {
														if (Object.values(GAME_TEAM_FORMAT).includes(value)) {
															return Promise.resolve();
														}
														return Promise.reject(t("validation.field_required"));
													}
												}
											]}
										>
											<Radio.Group
												options={[
													{
														label: (
															<span title={t(`pages.dashboard.games.teamFormat_${GAME_TEAM_FORMAT.INTERNATIONAL_CLUBS}`)}>
																{t(`pages.dashboard.games.teamFormat_${GAME_TEAM_FORMAT.INTERNATIONAL_CLUBS}`)}
															</span>
														),
														value: GAME_TEAM_FORMAT.INTERNATIONAL_CLUBS
													},
													{
														label: (
															<span title={t(`pages.dashboard.games.teamFormat_${GAME_TEAM_FORMAT.NATIONAL_TEAMS}`)}>
																{t(`pages.dashboard.games.teamFormat_${GAME_TEAM_FORMAT.NATIONAL_TEAMS}`)}
															</span>
														),
														value: GAME_TEAM_FORMAT.NATIONAL_TEAMS
													}
												]}
												onChange={(e) => setTeamFormat(e.target.value)}
												className="vs--flex"
											/>
										</FormItem>
									</Col>
									<Col span={24}>
										<FormItem
											label={`${t("pages.dashboard.games.teamOrClubs")} *`}
											name="teams"
											rules={[
												{
													validator: (_, value) => {
														if (!Array.isArray(value)) {
															return Promise.reject(t("validation.field_required"));
														}
														if (value.length < 10 && !value.includes(ALL_OPTION_ID)) {
															return Promise.reject(t("validation.must_be_more").replace("%X%", "10 teams"));
														}
														return Promise.resolve();
													}
												}
											]}
										>
											<SearchableSelect
												mode="tags"
												placeholder={t("pages.dashboard.games.teamOrClubs")}
												maxTagCount="responsive"
												items={teamsData}
												valueProp={(item) => item.value}
												textProp={(item) => item.text}
												renderText={(item) => (
													<div className="vs--flex vs--justify-start vs--align-center">
														{item.value !== ALL_OPTION_ID && <Flag code={item.value} gameType={currentGameType} />}
														{item.text}
													</div>
												)}
												getPopupContainer={(target) => target.parentNode}
												onChange={(selectedTeamOrClubs) => {
													switch (true) {
														case selectedTeamOrClubs.at(0) === ALL_OPTION_ID && selectedTeamOrClubs.length > 1:
															setFieldsValue({ teams: selectedTeamOrClubs.slice(1) });
															break;
														case selectedTeamOrClubs.at(-1) === ALL_OPTION_ID && selectedTeamOrClubs.length > 0:
														case selectedTeamOrClubs.length === allTeams.length && allTeams.length > 0:
															setFieldsValue({ teams: [ALL_OPTION_ID] });
															break;
														case selectedTeamOrClubs.length === 0 && allTeams.length === 0:
															setFieldsValue({ teams: [] });
															break;
														default:
															setFieldsValue({ teams: [...selectedTeamOrClubs] });
															break;
													}
													validateFields(["teams"]);
												}}
											/>
										</FormItem>
									</Col>
								</Fragment>
							) : null}
							<Col span={24}>
								<FormItem label={t("pages.dashboard.games.specify_partner")} name="partnerId">
									<Radio.Group
										options={[
											{
												label: (
													<div className="partners-checkbox">
														<i className="icon-partner-specific"></i>
														<span title={`${t("pages.dashboard.games.partner_specific")} (${globalPartner?.name ?? ""})`}>
															{t("pages.dashboard.games.partner_specific")} ({globalPartner?.name ?? ""})
														</span>
													</div>
												),
												value: globalPartnerId
											},
											{
												label: (
													<div className="partners-checkbox">
														<i className="icon-generic"></i>
														<span title={t("pages.dashboard.games.generic")}>{t("pages.dashboard.games.generic")}</span>
													</div>
												),
												value: ""
											}
										]}
										onChange={(e) => setSelectedPartnerId(e.target.value)}
										className="partner-type-radio-group"
									></Radio.Group>
								</FormItem>
							</Col>
						</Fragment>
					) : null}
					<Col span={24}>
						<div className="inline-form-checkbox">
							<label>{t("pages.dashboard.games.is_test_game")}</label>
							<FormItem className="inline-form-item-control" name="isTesting" valuePropName="checked">
								<Checkbox disabled={Boolean(selectedPartner?.isTesting)} />
							</FormItem>
						</div>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

/** GameAddComponent propTypes
 * PropTypes
 */
GameAddComponent.propTypes = {
	/** Redux state property, is true when creating game request is in process */
	isSaving: PropTypes.bool,
	/** Redux action to create game */
	createGame: PropTypes.func,
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Redux state property, represents the array of partners  */
	globalPartners: PropTypes.arrayOf(partnerType),
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	allTeams: PropTypes.array,
	isAllTeamsLoading: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	createGame: (gameData, onSuccess) => {
		dispatch(createGame(gameData, onSuccess));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(true, false, gameCategory));
	},
	getAllTeams: ({ teamFormat }) => {
		dispatch(getAllTeams({ teamFormat }));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		globalPartners: state.partner.globalPartners,
		globalPartnerId: state.partner.globalPartnerId,
		allTeams: state.games.allTeams.data,
		isAllTeamsLoading: state.games.allTeams.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameAddComponent);
