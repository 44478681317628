import { mergeClassNames } from "utils/common.js";

const ColorResultBadge = ({ color, number }) => {
	return (
		<div className="vs--spin2Win-color-badge">
			<div className="vs--spin2Win-color-badge-color-container">
				<div className={mergeClassNames("vs--spin2Win-color-badge-color", color)}>{number}</div>
			</div>
			<div className="vs--spin2Win-color-badge-text-container">{`${number} - ${color}`}</div>
		</div>
	);
};

export default ColorResultBadge;
