import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
	AUTOSUGGESTION_SET_APIKEY_NAMES,
	AUTOSUGGESTION_SET_GAME_NAMES,
	AUTOSUGGESTION_SET_PARTNERS,
	AUTOSUGGESTION_SET_BETSHOP_NAMES,
	AUTOSUGGESTION_SET_USER_NAMES,
	AUTOSUGGESTION_SET_CASHIER_NAMES,
	AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES,
	AUTOSUGGESTION_SET_CONTROLLERS,
	AUTOSUGGESTION_SET_BONUS_NAMES,
	AUTOSUGGESTION_SET_JACKPOT_NAMES,
	AUTOSUGGESTION_SET_SYSTEM_MARKETS,
	AUTOSUGGESTION_SET_CURRENCY_NAMES,
	AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES,
	AUTOSUGGESTION_SET_TERMINAL_NAMES
} from "./../../../actionTypes";

const setApikeyNames = (data) => ({
	type: AUTOSUGGESTION_SET_APIKEY_NAMES,
	payload: { data }
});

const setPartnersNames = (data) => ({
	type: AUTOSUGGESTION_SET_PARTNERS,
	payload: { data }
});

const setGameNames = (data) => ({
	type: AUTOSUGGESTION_SET_GAME_NAMES,
	payload: { data }
});

const setBetshopNames = (data) => ({
	type: AUTOSUGGESTION_SET_BETSHOP_NAMES,
	payload: { data }
});

const setTerminalNames = (data) => ({
	type: AUTOSUGGESTION_SET_TERMINAL_NAMES,
	payload: { data }
});

const setUserNames = (data) => ({
	type: AUTOSUGGESTION_SET_USER_NAMES,
	payload: { data }
});

const setAccessManagerNames = (data) => ({
	type: AUTOSUGGESTION_SET_ACCESS_MANAGER_NAMES,
	payload: { data }
});

const setCashierNames = (data) => ({
	type: AUTOSUGGESTION_SET_CASHIER_NAMES,
	payload: { data }
});

const setPermissionGroupNames = (data) => ({
	type: AUTOSUGGESTION_SET_PERMISSION_GROUP_NAMES,
	payload: { data }
});

const setControllers = (data) => ({
	type: AUTOSUGGESTION_SET_CONTROLLERS,
	payload: { data }
});

const setBonusNames = (data) => ({
	type: AUTOSUGGESTION_SET_BONUS_NAMES,
	payload: { data }
});

const setJackpotNames = (data) => ({
	type: AUTOSUGGESTION_SET_JACKPOT_NAMES,
	payload: { data }
});

const setSystemMarkets = (data) => ({
	type: AUTOSUGGESTION_SET_SYSTEM_MARKETS,
	payload: { data }
});

const setCurrencyNames = (data) => ({
	type: AUTOSUGGESTION_SET_CURRENCY_NAMES,
	payload: { data }
});

export const getApikeyNames = ({ partnerId = null, params = null } = { partnerId: null, params: null }) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_APIKEYS}`,
			method: Methods.GET,
			params: {
				SelectedPartnerId: partnerId,
				...params
			}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setApikeyNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getBetshopNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_BETSHOPS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBetshopNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getTerminalNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_TERMINALS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setTerminalNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getGameNames = (gameChannel) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_GAMES}`,
			method: Methods.GET,
			...(gameChannel && { params: { gameChannel } })
		})
			.then(({ data: { value: data } }) => {
				dispatch(setGameNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getPartnersNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_PARTNERS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPartnersNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getUserNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_USERS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setUserNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getAccessManagerNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_ACCESS_MANAGERS}`,
			method: Methods.GET
		})
			.then(({ data: { value } }) => {
				dispatch(setAccessManagerNames(value));
			})
			.catch(Function.prototype);
	};
};

export const getCashierNames = (projectId) => {
	return (dispatch) => {
		const params = {};
		if (projectId) {
			params.projectId = projectId;
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_CASHIERS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: data } }) => {
				dispatch(setCashierNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getPermissionGroupNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_PERMISSION_GROUPS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPermissionGroupNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getControllers = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_CONTROLLERS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setControllers(data));
			})
			.catch(Function.prototype);
	};
};

export const getBonusNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_BONUSES}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setBonusNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getJackpotNames = ({ params: { type = null, excludeIds = [] } } = { params: {} }) => {
	return (dispatch) => {
		const joinedArrayParams = excludeIds.map((i) => `excludeIds=${i}`).join("&");
		const additionalQueryParams = joinedArrayParams.length ? "?" + joinedArrayParams : "";

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_JACKPOT_NAMES}${additionalQueryParams}`,
			method: Methods.GET,
			params: { type }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setJackpotNames(data));
			})
			.catch(Function.prototype);
	};
};

export const getSystemMarkets = ({ params }) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_MARKETS}`,
			method: Methods.GET,
			params
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSystemMarkets(data));
			})
			.catch(Function.prototype);
	};
};

export const getCurrencyNames = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTOSUGGESTION_GET_CURRENCIES}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setCurrencyNames(data));
			})
			.catch(Function.prototype);
	};
};
