import { PROJECT_TYPE } from "constants/common.constants";
import { TRANSACTION_TYPE } from "constants/transactions.constants";

import {
	TRANSACTIONS_BEFORE,
	TRANSACTIONS_FINISH,
	SET_TRANSACTIONS,
	SET_TRANSACTIONS_SORTING,
	SET_TRANSACTIONS_FILTERS,
	TRANSACTION_HISTORY_BEFORE,
	TRANSACTION_HISTORY_FINISH,
	TRANSACTION_RESEND_BEFORE,
	TRANSACTION_RESEND_FINISH,
	SET_TRANSACTION_HISTORY,
	UPDATE_TRANSACTION,
	SET_ROLLBACKS_ACTIVE_TAB,
	SET_RESULTS_ACTIVE_TAB,
	SET_TRANSACTIONS_ACTIVE_TAB
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	let projectType, transactionType;
	switch (type) {
		case TRANSACTIONS_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case TRANSACTIONS_FINISH:
			return {
				...state,
				isLoading: false
			};
		case TRANSACTION_HISTORY_BEFORE:
			return {
				...state,
				isHistoryLoading: true
			};
		case TRANSACTION_HISTORY_FINISH:
			return {
				...state,
				isHistoryLoading: false
			};
		case TRANSACTION_RESEND_BEFORE:
			return {
				...state,
				isResendInProcess: true
			};
		case TRANSACTION_RESEND_FINISH:
			return {
				...state,
				isResendInProcess: false
			};
		case SET_TRANSACTIONS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";
			transactionType = payload.type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";
			return {
				...state,
				[transactionType]: {
					...state[transactionType],
					[projectType]: {
						...state[transactionType][projectType],
						transactions: payload.transactions.item2,
						total: payload.transactions.item1
					}
				}
			};
		case SET_TRANSACTIONS_SORTING:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";
			transactionType = payload.type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";
			return {
				...state,
				[transactionType]: {
					...state[transactionType],
					[projectType]: {
						...state[transactionType][projectType],
						sorting: payload.sorting
					}
				}
			};
		case SET_TRANSACTIONS_FILTERS:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";
			transactionType = payload.type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";
			return {
				...state,
				[transactionType]: {
					...state[transactionType],
					[projectType]: {
						...state[transactionType][projectType],
						filters: payload.filters
					}
				}
			};
		case SET_TRANSACTION_HISTORY:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";
			transactionType = payload.type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";
			return {
				...state,
				[transactionType]: {
					...state[transactionType],
					[projectType]: {
						...state[transactionType][projectType],
						transactions: state[transactionType][projectType].transactions.map((tr) =>
							tr.id === payload.id ? { ...tr, history: payload.history } : tr
						)
					}
				}
			};
		case UPDATE_TRANSACTION:
			projectType = payload.projectType === PROJECT_TYPE.ONLINE ? "online" : payload.projectType === PROJECT_TYPE.RETAIL ? "retail" : "ssbt";
			transactionType = payload.type === TRANSACTION_TYPE.ROLLBACK ? "rollbacks" : "results";
			return {
				...state,
				[transactionType]: {
					...state[transactionType],
					[projectType]: {
						...state[transactionType][projectType],
						transactions: state[transactionType][projectType].transactions.map((tr) =>
							tr.id === payload.transaction.id
								? {
										...payload.transaction,
										history: tr.history
											? [
													{
														status: payload.transaction.lastStatus,
														actionBy: payload.transaction.lastActionBy,
														actionTime: payload.transaction.lastActionTime,
														message: payload.transaction.lastMessage,
														id: payload.transaction.id
													},
													...tr.history
												]
											: tr.history
									}
								: tr
						)
					}
				}
			};
		case SET_ROLLBACKS_ACTIVE_TAB:
			return {
				...state,
				rollbacks: {
					...state.rollbacks,
					rollbacksPageActiveTab: payload.tab
				}
			};
		case SET_RESULTS_ACTIVE_TAB:
			return {
				...state,
				results: {
					...state.results,
					resultsPageActiveTab: payload.tab
				}
			};
		case SET_TRANSACTIONS_ACTIVE_TAB:
			return {
				...state,
				transactionsPageActiveTab: payload.tab
			};
		default:
			return state;
	}
};
