import { useTranslation } from "react-i18next";
import Breadcrumbs from "components/common/breadcrumbs";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import SSBTEventsComponent from "./events";

/** Events Page Component */
const EventsComponent = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="dashboard-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.events.events") }]} />
				<SSBTEventsComponent activeTab={PROJECT_TYPE.SSBT.toString()} gameCategory={GAME_CATEGORY.SCHEDULED} />
			</div>
		</>
	);
};

export default EventsComponent;
