import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Radio } from "antd";
import Table from "components/common/table";
import Question from "components/common/question";
import CurrencyAddComponent from "./currency-add.component";

import currencyType from "types/currency/currency.type";

import { getPartnerCurrencies, updatePartnerCurrency, deletePartnerCurrency } from "store/actions/dashboard/partners/currencies.action";
import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";

import { RATE_SOURCE_TYPE } from "constants/currency.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { hasPermission } from "utils/permissions";

/** Partner Edit Page Currecies Tab Component */
const CurrencyComponent = ({ currencies, getPartnerCurrencies, getUserInfo, isLoading, updatePartnerCurrency, deletePartnerCurrency }) => {
	const { t } = useTranslation();

	/** State to show/hide currecny add/edit popup */
	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const [editingCurrency, setEditingCurrency] = useState(null);
	const [defaultCurrency, setDefaultCurrency] = useState(null);
	const [handlingCurrency, setHandlingCurrency] = useState(null);

	/** Columns of table */
	const columns = [
		{
			title: t("pages.dashboard.currency.name"),
			dataIndex: "name",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.code"),
			dataIndex: "code",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.symbol"),
			dataIndex: "symbol",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.rate"),
			dataIndex: "rate",
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.rate_overridden"),
			dataIndex: "rateSourceType",
			sorter: false,
			render: (value) => (value === RATE_SOURCE_TYPE.OVERRIDDEN ? t("common.yes") : t("common.no"))
		},
		{
			title: t("pages.dashboard.currency.fav_bets"),
			dataIndex: "favoriteBets",
			render: (value) => value.join(", "),
			sorter: false
		},
		{
			title: t("pages.dashboard.currency.default"),
			dataIndex: "isDefault",
			render: (value, record) => <Radio value={record.code} disabled={!record.enabled} style={{ margin: 0 }} defaultChecked={value}></Radio>,
			sorter: false,
			align: "center"
		}
	];

	/** Close currecny add/edit popup, after save */
	useEffect(() => {
		setShowCreatePopup(false);
		setEditingCurrency(null);
		const defaultCur = currencies.find((c) => c.isDefault);
		defaultCur && setDefaultCurrency(defaultCur.code);
	}, [currencies]);

	/** Fires when default currency is changed
	 * @function
	 * @memberOf CurrencyComponent
	 */
	const handleDefaultCurrencyChange = (code) => {
		const selectedCurrency = currencies.find((c) => c.code === code);
		updatePartnerCurrency({
			enabled: true,
			code: selectedCurrency.code,
			rate: selectedCurrency.rateSourceType === RATE_SOURCE_TYPE.DEFAULT ? null : selectedCurrency.rate,
			favoriteBets: selectedCurrency.favoriteBets,
			retailFavoriteBets: selectedCurrency.retailFavoriteBets,
			terminalFavoriteBets: selectedCurrency.terminalFavoriteBets,
			decimalCount: selectedCurrency.decimalCount,
			onlineBetLimits: selectedCurrency.onlineBetLimits,
			retailBetLimits: selectedCurrency.retailBetLimits,
			terminalBetLimits: selectedCurrency.terminalBetLimits,
			isDefault: true
		});
		setHandlingCurrency(null);
		setDefaultCurrency(code);
		getUserInfo();
	};

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.CREATE }) && (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button"
						onClick={() => {
							setShowCreatePopup("add");
						}}
					>
						<span>{t("pages.dashboard.currency.add_new_currency")}</span>
					</Button>
				</div>
			)}
			<Radio.Group
				style={{ width: "100%" }}
				value={defaultCurrency}
				onChange={(e) => setHandlingCurrency(e.target.value)}
				disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.MODIFY })}
			>
				<Table
					loading={isLoading}
					columns={columns}
					data={currencies}
					loadFn={getPartnerCurrencies}
					total={currencies.length}
					actions={{
						activate: hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.MODIFY })
							? {
									isChecked: (record) => record.enabled,
									messageKey: "currency",
									handler: (isChecked, record) =>
										updatePartnerCurrency({
											enabled: isChecked,
											code: record.code,
											rate: record.rateSourceType === RATE_SOURCE_TYPE.DEFAULT ? null : record.rate,
											favoriteBets: record.favoriteBets,
											retailFavoriteBets: record.retailFavoriteBets,
											decimalCount: record.decimalCount,
											onlineBetLimits: record.onlineBetLimits,
											retailBetLimits: record.retailBetLimits,
											terminalFavoriteBets: record.terminalFavoriteBets,
											terminalBetLimits: record.terminalBetLimits,
											isDefault: record.isDefault
										}),
									disabled: (record) => !record.isDeletable
							  }
							: null,
						delete: hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.DELETE })
							? {
									messageKey: "currency",
									handler: (record) => {
										deletePartnerCurrency(record.code);
									},
									disabled: (record) => !record.isDeletable,
									title: (record) =>
										record.isDeletable
											? t("common.delete")
											: record.isDefault
											? t("pages.dashboard.currency.currency_is_set_as_default")
											: t("pages.dashboard.currency.currency_is_set_as_default_by_project")
							  }
							: null,
						edit: hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_CURRENCIES, action: PERMISSION_ACTION.MODIFY })
							? {
									handler: (record) => {
										setEditingCurrency(record);
										setShowCreatePopup("edit");
									}
							  }
							: null
					}}
					isDisabled={(record) => !record.enabled}
					noPagination={true}
					uniqueKey="code"
				/>
			</Radio.Group>
			<Question
				type="confirm"
				onOk={() => handleDefaultCurrencyChange(handlingCurrency)}
				onCancel={() => setHandlingCurrency(null)}
				isVisible={handlingCurrency !== null}
				message={t("pages.dashboard.currency.default_currency_text")}
			/>
			{showCreatePopup && (
				<CurrencyAddComponent
					onClose={() => {
						setShowCreatePopup(false);
						setEditingCurrency(null);
					}}
					mode={showCreatePopup}
					editingCurrency={editingCurrency}
				/>
			)}
		</div>
	);
};

/** CurrencyComponent propTypes
 * PropTypes
 */
CurrencyComponent.propTypes = {
	/** Redux action to get partner currencies */
	getPartnerCurrencies: PropTypes.func,
	/** Redux state property, represents the array of partner currencies  */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state property, is true when loading partner currencies */
	isLoading: PropTypes.bool,
	/** Redux action to update partner currency */
	updatePartnerCurrency: PropTypes.func,
	/** Redux action to delete partner currency */
	deletePartnerCurrency: PropTypes.func,
	/** Redux action to get user info */
	getUserInfo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerCurrencies: () => {
		dispatch(getPartnerCurrencies());
	},
	updatePartnerCurrency: (currency) => {
		dispatch(updatePartnerCurrency(currency));
	},
	deletePartnerCurrency: (code) => {
		dispatch(deletePartnerCurrency(code));
	},
	getUserInfo: () => {
		dispatch(getUserInfo(true));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.partner.currency.isLoading,
		currencies: state.partner.currency.currencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyComponent);
